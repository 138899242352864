<template>
  <iframe
    class="instagram-media instagram-media-rendered"
    id="instagram-embed-0"
    :src="embedSrc"
    allowtransparency="true"
    allowfullscreen="true"
    frameborder="0"
    height="frameHeight"
    data-instgrm-payload-id="instagram-media-payload-0"
    scrolling="auto"
    style="background: white; max-width: 480px; width: calc(100% - 50px); border-radius: 3px; border: 1px solid rgb(219, 219, 219); box-shadow: none; display: block; margin: 0px 0px 12px; min-width: 240px; padding: 0px;"
  ></iframe>
</template>

<script>
import $ from "jquery";

export default {
  name: "GalleryDetail",
  props: ["embedSrc"],
  data: () => {
    return {
      frameHeight: 640
    };
  },
  methods: {
    setHeiResponse: function() {
      let hei = $(this).innerHeight();
      if (hei > 640) {
        this.frameHeight = 640;
      } else {
        this.frameHeight = 400;
      }
    }
  },
  mounted() {
    //this.setHeiResponse();
  }
};
</script>
