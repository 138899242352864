<template>
  <section class="sec--gallery">
    <div
      class="cont--bg"
      :style="{ 'background-image': 'url(' + bgImg + ')' }"
    ></div>
    <div class="cont--top-gl">
      <div class="top--title" v-html="sectionTxt.title"></div>
    </div>
    <ul class="cont--gallery">
      <li
        class="box--gl"
        v-for="(item, imgIndex) in instaDatas"
        :key="imgIndex"
        @click="(isShowDetail = true), (clickSrc = item.linkUrl)"
        :style="{
          backgroundImage: 'url(' + item.imgUrl + ')'
        }"
      >
        <div class="wrap--playbtn" v-if="item.type == 'VIDEO'">
          <font-awesome-icon
            icon="play-circle"
            class="font-play"
          ></font-awesome-icon>
        </div>
        <div class="wrap--txt">
          <div class="txt--name">
            <font-awesome-icon
              icon="user-edit"
              class="font-like"
            ></font-awesome-icon>
            {{ item.title }}
          </div>
        </div>
      </li>
    </ul>
    <div class="btn--more" v-show="isShowPage" @click="importInstaPic(nextUrl)">
      Show More
    </div>
    <div
      class="wrap--gal-detail"
      v-show="isShowDetail"
      @click="isShowDetail = false"
    >
      <div class="btn--close" @click="isShowDetail = false">X</div>
      <GalleryDetail :embed-src="clickSrc"></GalleryDetail>
    </div>
  </section>
</template>

<script>
import $ from "jquery";
import bgImg from "@/assets/gallery/bg_gallery.jpg";
import GalleryDetail from "../components/gallery/GalleryDetail";

export default {
  name: "Gallery",
  components: {
    GalleryDetail
  },
  data: () => {
    return {
      bgImg: bgImg,
      sectionTxt: {
        title: `Gallery`
      },
      instaDatas: [],
      nextUrl: "",
      isShowPage: true,
      index: null,
      isShowDetail: false,
      clickSrc: "",
      nowSelectID: 0
    };
  },
  methods: {
    importInstaPic: function(url) {
      let vm = this;
      vm.maxId = "";
      const baseURI = url;
      this.$http.get(baseURI).then(result => {
        let res = result.data;
        //console.log(res);
        //success
        for (let i in res.data) {
          let obj = {};
          let linkId = "";
          obj.title = res.data[i].username;
          linkId = res.data[i].permalink + "embed/?cr=1&v=12&wp=480";
          //console.log(linkId);
          obj.linkUrl = linkId;
          obj.type = res.data[i].media_type;
          if (obj.type == "VIDEO") {
            obj.imgUrl = res.data[i].thumbnail_url;
          } else {
            obj.imgUrl = res.data[i].media_url;
          }
          vm.instaDatas.push(obj);
        }

        if (typeof res.paging.next !== "undefined") {
          vm.isShowPage = true;
          vm.nextUrl = res.paging.next;
        } else {
          vm.isShowPage = false;
        }
      });
    },
    layerOpenPop: function() {
      $("html, body").css({ overflow: "hidden", height: "100%" });
      $("#pop").bind("touchmove", function(e) {
        e.preventDefault();
      });
      $("#pop .popIn").bind("touchmove", function(e) {
        e.stopPropagation();
      });
    },
    layerClosePop: function() {
      $("html, body").css({ overflow: "auto", height: "auto" });
      $("#pop").unbind("touchmove");
    }
  },
  mounted() {
    const tokenValue =
      "IGQVJXYW9iaEZAxUmxpM0xwSGlxV1JXamhfLXMwMVpKc082TDNoVXBieGp3aUlBMnNQOERveV9HdEVUWjNVcFRidkYxUUlfWGtYUHRvN2tBWG9KeWlvVlBBRlZAZAejVubnBRbkZABcW13";
    const baseURI = `https://graph.instagram.com/me/media?fields=username,media_type,media_url,thumbnail_url,permalink&access_token=${tokenValue}`;
    this.importInstaPic(baseURI);
  }
};
</script>
